import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Header = ({location}) => {
  const [isVisible, setVisible] = React.useState(false);
  const activeItem = location && location.pathname
  const toggleMenu = React.useCallback(() => setVisible(!isVisible), [isVisible]);
  return (
    <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/"><img src="../logo.png" width="112" height="28"/></Link>

        <a role="button" className={`navbar-burger${isVisible ? ` is-active` : ""}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={toggleMenu}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" className={`navbar-menu${isVisible ? ` is-active` : ""}`}>
        <div className="navbar-end">
          <Link className={`navbar-item${activeItem === "/" ?` isActive`: ''}`} to="/"><span className="item">Home</span></Link>
          <Link className={`navbar-item${activeItem === "/about/" ?` isActive`: ''}`} to="/about/"><span className="item">St-Martin</span></Link>
          {/*<Link className={`navbar-item${activeItem === "/donation" ?` isActive`: ''}`} to="/">Don !</Link>*/}
          <div className="navbar-item">
            <div className="buttons">
              <Link className="button is-primary" to="/shop/"><strong>Précommande</strong></Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
