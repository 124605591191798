import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, keywords, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:image`,
                content: 'http://tomhouse.fr/static/banner-martin.png',
              },
              {
                property: `og:url`,
                content: 'https://saintmartinparis.com',
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `viewport`,
                content: `width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0`,
              },

            ]
              .concat(
                keywords.length > 0
                  ? {
                    name: `keywords`,
                    content: keywords.join(`, `),
                  }
                  : [],
              )
              .concat(meta)}
          >
            <script type="application/ld+json">
              {
                `{
                "@context": "https://www.schema.org",
                "@type": "product",
                "brand": {
                  "@type": "Organization",
                  "name": "Saint Martin Paris"
                },
                "logo": "https://d33wubrfki0l68.cloudfront.net/46f31012a053dd5542e985f48178a8ffa04d33db/b8e94/logo.png",
                "name": "Tshirt Jacques",
                "image": "https://d33wubrfki0l68.cloudfront.net/6ea5724c9f0baa8eb97b93850737ac02aea4fe55/bb5e5/jacques_1.png",
                "description": "Tshirt engagé contre le cancer (5 tailles disponibles)",
                "mpn": "123456789",
                "sku": "saint-martin-1",
                "offers": {
                  "@type": "Offer",
                  "priceCurrency": "EUR",
                  "price": "35.00",
                  "url": "https://saintmartinparis.com/shop/",
                  "priceValidUntil": "2021-06-15",
                  "itemCondition": "http://schema.org/NewCondition",
                  "availability": "http://schema.org/PreOrder",
                  "seller": {
                    "@type": "Organization",
                    "name": "Saint Martin Paris"
                  }
                }
                }`
              }
            </script>
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
